<template>
  <div class="page-container">
    <div class="banner">
      <img class="img" src="../../../../assets/pricing/banner.png" alt="" />
      <div class="banner-title">
        <h2>{{t("pricing.banner_text")}}</h2>
        <h4>{{t("pricing.banner_title")}}</h4>
      </div>
    </div>
    <section class="sec-container">
        <div class="d-card-box">
            <div class="d-card">
                <div class="d-card-title">{{t("pricing.free.title")}}</div>
                <div class="d-card-btn"><button id="freeCradBtn">{{t("pricing.free.btn")}}</button></div>
                <div class="d-card-price">{{t("pricing.free.price")}}</div>
                <div class="d-card-line"></div>
                <div class="d-card-sub-title">{{t("pricing.free.sub_title")}}</div>
                <div class="d-card-text">{{t("pricing.free.text1")}}</div>
                <div class="d-card-text">{{t("pricing.free.text2")}}</div>
                <!-- <div class="d-card-text">{{t("pricing.free.text3")}}</div> -->
            </div>
            <div class="d-card">
                <div class="d-card-title">{{t("pricing.team.title")}}</div>
                <div class="d-card-btn btn-orange"><button id="teamCradBtn">{{t("pricing.team.btn")}}</button></div>
                <div class="d-card-price">{{t("pricing.team.price")}}</div>
                <div class="d-card-line"></div>
                <div class="d-card-sub-title">{{t("pricing.team.sub_title")}}</div>
                <div class="d-card-text">{{t("pricing.team.text1")}}</div>
                <div class="d-card-text">{{t("pricing.team.text2")}}</div>
                <div class="d-card-text">{{t("pricing.team.text3")}}</div>
                <img class="d-card-img" src="../../../../assets/pricing/star.png" alt="">
            </div>
            <div class="d-card">
                <div class="d-card-title">{{t("pricing.business.title")}}</div>
                <div class="d-card-btn"><button id="businessCradBtn">{{t("pricing.business.btn")}}</button></div>
                <div class="d-card-price">{{t("pricing.business.price")}}</div>
                <div class="d-card-line"></div>
                <div class="d-card-sub-title">{{t("pricing.business.sub_title")}}</div>
                <div class="d-card-text">{{t("pricing.business.text1")}}</div>
                <div class="d-card-text">{{t("pricing.business.text2")}}</div>
                <div class="d-card-text">{{t("pricing.business.text3")}}</div>
                <div class="d-card-text">{{t("pricing.business.text4")}}</div>
                <div class="d-card-text">{{t("pricing.business.text5")}}</div>
            </div>
        </div>
    </section>
    <div class="info-box">
      <h1 class="info-title">{{ t("pricing.compare_plans") }}</h1>
        <table class="table" border="0" cellpadding="0" cellspacing="0">
            <thead>
                <tr>
                    <th>服务内容</th>
                    <th>免费
                        <button id="freeTableBtn">创建免费账户</button>
                    </th>
                    <th class="btn-orange">团队
                        <button id="teamTableBtn">免费试用</button>
                    </th>
                    <th>企业
                        <button id="businessTableBtn">联系我们</button>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in tableData" :key="item.service">
                    <td>{{item.service}}</td>
                    <td><span v-if="item.type == '0'">{{item.free}}</span><img v-if="item.type == '1'" :src="require('../../../../assets/pricing/' + item.free)" alt=""></td>
                    <td><span v-if="item.type == '0'">{{item.team}}</span><img v-if="item.type == '1'" :src="require('../../../../assets/pricing/' + item.team)" alt=""></td>
                    <td><span v-if="item.type == '0'">{{item.business}}</span><img v-if="item.type == '1'" :src="require('../../../../assets/pricing/' + item.business)" alt=""></td>
                </tr>
            </tbody>
        </table>
    </div>
    <HelpForm/>
  </div>
</template>
<script>
import { useI18n } from "vue-i18n";
import HelpForm from '../../../../components/HelpForm/index.vue';
export default {
    name: 'PRICING',
    components: {HelpForm},
    data() {
        return {
            tableData: [
                {
                    service: '坐席',
                    free: '1',
                    team: '5',
                    business: '不限',
                    type: '0'
                }, {
                    service: '渠道',
                    free: '2',
                    team: '16',
                    business: '定制渠道',
                    type: '0'
                }, {
                    service: '用户月活',
                    free: '1000',
                    team: '10,000',
                    business: '不限',
                    type: '0'
                }, {
                    service: '数据保存',
                    free: '一个月',
                    team: '两年',
                    business: '不限',
                    type: '0'
                }, {
                    service: '云服务',
                    free: '美国',
                    team: '美国',
                    business: '美国，德国，新加坡',
                    type: '0'
                }, {
                    service: '私有云',
                    free: 'error.png',
                    team: 'error.png',
                    business: 'success.png',
                    type: '1'
                }, {
                    service: '政策',
                    free: 'GDPR,CCCPA,LGPD,HIPAA',
                    team: 'GDPR,CCCPA,LGPD,HIPAA',
                    business: 'GDPR,CCCPA,LGPD,HIPAA',
                    type: '0'
                }, {
                    service: '专属服务与支持',
                    free: 'error.png',
                    team: 'success.png',
                    business: 'success.png',
                    type: '1'
                }
            ]
        }
    },
    setup() {
        const { t } = useI18n();
        return {
        t,
        };
    }
}
</script>
<style lang="less" scoped>
.page-container {
  .banner {
    display: block;
    position: relative;
    z-index: 0;
    background: #000;
    overflow: hidden;
    height: 792px;
    width: 100%;
    .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .banner-title {
      color: #F4F5F5;
      // font-weight: bold;
      font-size: 20px;
      line-height: 22px;
      // font-size: 62px;
      position: absolute;
      top: 50%;
      left: 22%;
      transform: translateY(-50%);
      &::after {
        content: '';
        display: block;
        width: 140px;
        margin: 15px 0;
        border-bottom: 8px solid #FF9333;
        border-radius: 4px;
      }
      .title {
        display: block;
        margin-bottom: 30px;
        font-size: 60px;
      }
    }
  }
  .sec-container {
      width: 1140px;
      min-height: 240px;
      margin: 0 auto;
      position: relative;
      .d-card-box {
          width: 100%;
          display: flex;
          position: absolute;
          top: -200px;
          .d-card {
            position: relative;
            flex: 1;
            margin-right: 30px;
            padding: 50px 20px;
            background: #ffffff;
            border-radius: 25px;
            box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
            text-align: center;
            font-size: 14px;
            color: #666666;
            &:last-child {
                margin-right: 0;
            }
            .d-card-title {
                font-size: 20px;
                color: #333333;
                font-weight: bold;
            }
            .d-card-btn {
                width: 60%;
                margin: 20px auto;
                button {
                    width: 100%;
                    padding: 8px;
                    border: 1px solid #FF9333;
                    border-radius: 20px;
                    background: #ffffff;
                    color: #ff9333;
                    cursor: pointer;
                }
                &.btn-orange {
                    button {
                        background: #ff9333;
                        color: #ffffff;
                    }
                }
            }
            .d-card-price {
                margin: 10px;
            }
            .d-card-line {
                width: 70%;
                margin: 15px auto;
                border: 1px solid #dddddd;
            }
            .d-card-sub-title {
                width: 70%;
                text-align: left;
                margin: 15px auto;
                font-size: 14px;
            }
            .d-card-text {
                width: 70%;
                text-align: left;
                margin: 15px auto;
                position: relative;
                font-size: 12px;
                &::before {
                    content: '';
                    display: block;
                    width: 4px;
                    height: 10px;
                    border: 2px solid transparent; 
                    border-right-color: #02C50B;
                    border-bottom-color: #02C50B;
                    position: absolute;
                    left: -20px;
                    transform: rotate(45deg);
                }
            }
            .d-card-img {
                position: absolute;
                top: 0;
                right: 0;
            }
          }
      }
  }
  .info-box {
    max-width: 1140px;
    margin: 80px auto;
    text-align: center;
    padding: 20px 0;
    .info-title::after {
      content: "";
      display: block;
      width: 120px;
      text-align: center;
      margin: 20px auto 40px;
      background: #ff9333;
      border: 3px solid #ff9333;
      border-radius: 3px;
    }
    .table {
        width: 100%;
        user-select: none;
        tr {
            height: 80px;
            th,td {
                padding: 0 30px;
            }
            td {
                img{
                    width: 26px;
                }
            }
            th {
                button {
                    width: 100%;
                    padding: 8px;
                    border: 1px solid #FF9333;
                    border-radius: 20px;
                    background: #ffffff;
                    color: #ff9333;
                    cursor: pointer;
                }
                &.btn-orange {
                    button {
                        background: #ff9333;
                        color: #ffffff;
                    }
                }
            }
            th:first-child {
                width: 40%;
                text-align: left;
                padding: 0 20px;
            }
            td:first-child {
                text-align: left;
                box-shadow: 10px 0px 9px 0px rgba(255, 233, 214, 0.46);
                padding: 0 20px;
            }
            &:nth-child(2n) {
                background: rgba(255, 147, 51, 0.1);;
            }
        }
        thead {
            tr {
                th {
                    border-bottom: 1px solid#e7e7e7;
                }
            }
        }
    }
  }
}
</style>
